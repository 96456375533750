import { RadioGroup, Switch, Typography } from "@eyectrl/baseplate-design-system"
import { usePatchUserSettingsV2 } from "@features/userSettings/hooks/usePatchUserSettingsV2"
import { IUserSettings } from "@features/userSettings/userSettingsTypes"
import { useActiveEyed } from "@hooks/useActiveEyed"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { FindingsNotificationSettings } from "../FindingsNotificationSettings"

export type DigestInterval = "weekly" | "monthly" | "none"

interface Props {
    userSettings: IUserSettings
}

export const CyberGuardNotificationOptions: React.FC<Props> = ({ userSettings }) => {
    const { t } = useTranslation()
    const activeEyed = useActiveEyed()
    const { mutate } = usePatchUserSettingsV2(activeEyed)

    // Stores the selected digest setting
    const [digestSetting, setDigestSetting] = useState<DigestInterval>(userSettings.digest_notification_interval)
    // The options in the digest radio group
    const digestSettingOptions = [
        { label: t("common:userMenu.notifications.weekly"), value: "weekly" },
        { label: t("common:userMenu.notifications.monthly"), value: "monthly" },
    ]
    // The selected digest radio option ( falls back to monthly if the digest setting is set to "none")
    const selectedDigestRadioOption =
        digestSettingOptions.find((option) => option.value === digestSetting) ?? digestSettingOptions[1]

    const onChangeDigestSetting = (interval: DigestInterval): void => {
        setDigestSetting(interval)

        mutate({
            digest_notification_interval: interval,
        })
    }

    return (
        <div className="space-y-4">
            <FindingsNotificationSettings userSettings={userSettings} />

            <section className="card p-6">
                <div className="flex gap-2 items-center">
                    <Switch
                        checked={digestSetting !== "none"}
                        disabled={false}
                        onChange={(newValue) => {
                            if (newValue) {
                                onChangeDigestSetting("monthly")
                            } else {
                                onChangeDigestSetting("none")
                            }
                        }}
                    />
                    <Typography variant="header-4">{t("settings:notifications.digest.title")}</Typography>
                </div>
                <Typography color="text-secondary" className="mt-2">
                    {t("settings:notifications.digest.subtitle")}
                </Typography>

                <div className="mt-4">
                    <RadioGroup
                        selected={selectedDigestRadioOption}
                        disabled={digestSetting === "none"}
                        className="space-y-3"
                        onChange={(value) => {
                            if (value === "monthly") {
                                onChangeDigestSetting("monthly")
                            }

                            if (value === "weekly") {
                                onChangeDigestSetting("weekly")
                            }
                        }}
                        options={digestSettingOptions}
                    ></RadioGroup>
                </div>
            </section>
        </div>
    )
}
