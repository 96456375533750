import * as React from "react"
import { useState } from "react"
import { LogoutOptions, User, useAuth0 } from "@auth0/auth0-react"
import { Alert } from "../components/designSystem/feedback/notifications/Alert"
import { EyeOrganisation, useUserOrganisations } from "@features/accountSwitcher/hooks/useUserOrganisations"
import { useParams } from "react-router-dom"
import { Env, getEnv } from "@/config/envConfig"
import { useUserInformation } from "@features/userSettings/hooks/useUserInformation"
import { PortalLoadingScreen } from "@components/portalLoadingScreen/PortalLoadingScreen"
import { useTranslation } from "react-i18next"
import { PostHogPropertySync } from "@/posthog/PostHogPropertySync"
import { LoginErrorMessage } from "@components/loginErrorMessage/LoginErrorMessage"
import { UserIdentifier } from "@/posthog/UserIdentifier"
import { organisationUtils } from "@utils/organisationUtils"
import { useLogoutUser } from "@hooks/useLogoutUser"
import { DEFAULT_USER_PROFILE, useUserProfile } from "@features/userProfile/useUserProfile"
import { isNullish } from "@utils/formatUtils"
import { IUserProfile } from "@features/userProfile/types"

interface IUserContext {
    initialEyed: string
    activeOrganisation: EyeOrganisation | undefined
    isRiskDevelopmentModeEnabled: boolean
    setRiskDevelopmentModeEnabled: (enabled: boolean) => void
    language: string
    userOrganisations: EyeOrganisation[]
    logoutUser: (options?: LogoutOptions | undefined) => void
    isPortalAdmin: boolean
    user: User
    userProfile: IUserProfile
    updateUserProfileProperty: (property: Partial<IUserProfile>) => void
}

export const UserContext = React.createContext<IUserContext>({
    initialEyed: "",
    activeOrganisation: undefined,
    language: "",
    userOrganisations: [],
    isRiskDevelopmentModeEnabled: false,
    logoutUser: () => {
        console.warn("logout call not implemented in user context")
    },
    setRiskDevelopmentModeEnabled: (enabled: boolean): void => {
        // placeholder context function to make sure the function always exists
        // eslint-disable-next-line
        console.warn(`risk switch not implemented: ${enabled}`)
    },
    isPortalAdmin: false,
    user: {},
    userProfile: DEFAULT_USER_PROFILE,
    updateUserProfileProperty: () => {
        console.warn("updateUserProfileProperty not implemented in user context")
    },
})

export const UserContextProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
    const language = "en"
    const environment = getEnv()
    const { t } = useTranslation()
    const { eyed: urlParamsEyed } = useParams()
    const { logoutUser } = useLogoutUser()

    /**
     *
     * Auth0 Data fetching
     *
     */
    const { isAuthenticated, user, error: auth0Error } = useAuth0()

    /**
     *
     * User organisations data
     *
     */
    const { data: userOrganisationsData, error: organisationsError } = useUserOrganisations()
    const userHasNoOrganisations = userOrganisationsData?.data === null || userOrganisationsData?.data?.length === 0
    // If the active eyed is not set, we determine the initial eyed based on the user's organisations
    const initialEyed =
        urlParamsEyed &&
        urlParamsEyed !== "" &&
        organisationUtils.checkIfEyedExistsInOrganisationsData(urlParamsEyed, userOrganisationsData?.data ?? [])
            ? urlParamsEyed
            : organisationUtils.determineInitialOrganisationEyed(userOrganisationsData?.data ?? [])
    const activeOrganisation = userOrganisationsData?.data?.find((organisation) => organisation.eyed === initialEyed)
    const userOrganisations = userOrganisationsData?.data ?? []

    /**
     *
     * User information data ( is admin check )
     *
     */
    const { data: userInformationData } = useUserInformation()
    //  Additional check, only the actual boolean value should return true. Empty strings, nulls, "true" should not validate correctly.
    const isPortalAdmin = userInformationData?.data?.is_admin === true ? true : false

    /**
     *
     * Risk Development Mode
     *
     */
    const [isRiskDevelopmentModeEnabled, setIsRiskDevelopmentModeEnabled] = useState(false)
    const setRiskDevelopmentModeEnabled = (enabled: boolean): void => {
        if (environment === Env.development || environment === Env.test) {
            setIsRiskDevelopmentModeEnabled(enabled)
        }

        return
    }

    /**
     * User Profile Data
     */
    const { activeUserProfile, updateUserProfileProperty } = useUserProfile(initialEyed ?? "")

    if (auth0Error || organisationsError || userHasNoOrganisations) {
        return (
            <PortalLoadingScreen>
                <LoginErrorMessage logoutUser={logoutUser} />
            </PortalLoadingScreen>
        )
    }

    // If auth0 is still authenticating OR the active organisation / eyed is still being setup, we show the loading screen.
    // We want to ensure auth0 is loaded AND an organisation is active before loading any other components
    if (
        !isAuthenticated ||
        !initialEyed ||
        isNullish(activeOrganisation) ||
        isNullish(user) ||
        isNullish(activeUserProfile)
    ) {
        return (
            <PortalLoadingScreen>
                <div className="relative">
                    <Alert title={t("common:loadingMessages.loadingPortal")} type="loading" />
                </div>
            </PortalLoadingScreen>
        )
    }

    return (
        <UserContext.Provider
            value={{
                language,
                initialEyed,
                activeOrganisation,
                userOrganisations: userOrganisations,
                logoutUser,
                isRiskDevelopmentModeEnabled,
                setRiskDevelopmentModeEnabled,
                isPortalAdmin,
                user,
                userProfile: activeUserProfile,
                updateUserProfileProperty,
            }}
        >
            {/* Component that awaits the auth0 user and then identifies this user with the PostHog instance provided by the PostHogContext */}
            <UserIdentifier>
                {/* Component that adds user properties once the user and organisation have been identified */}
                <PostHogPropertySync activeOrganisation={activeOrganisation} userOrganisations={userOrganisations}>
                    {children}
                </PostHogPropertySync>
            </UserIdentifier>
        </UserContext.Provider>
    )
}
