import { UserContext } from "@/providers/UserContext"
import { NewLabel } from "@components/newLabel/NewLabel"
import React, { useContext } from "react"

export const DetectionsNavigationLabel: React.FC = () => {
    const { userProfile } = useContext(UserContext)
    // The inverse of has visited, if the user has visited detections we don't want to show the is new label
    const showLabel = !userProfile.has_visited_detections_v2

    if (showLabel) {
        return <NewLabel />
    }
}
