import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import en_navigation from "./locales/en/navigation.json"
import en_pages from "./locales/en/pages.json"
import en_common from "./locales/en/common.json"
import en_cookiebanner from "./locales/en/cookiebanner.json"
import en_managementReports from "./locales/en/managementReports.json"
import en_threatHunting from "./locales/en/threatHunting.json"
import en_introductionHotspots from "./locales/en/introductionHotspots.json"
import en_toastMessages from "./locales/en/toastMessages.json"
import en_loginPage from "./locales/en/loginPage.json"
import en_versionChecker from "./locales/en/versionChecker.json"
import en_dashboardMetrics from "./locales/en/dashboardMetrics.json"
import en_sentinelOnboarding from "./locales/en/sentinelOnboarding.json"
import en_detections from "./locales/en/detections.json"
import en_settings from "./locales/en/settings.json"

export const languages = {
    en: {
        displayName: "English",
        abbr: "EN",
    },
}

const resources = {
    en: {
        navigation: en_navigation,
        pages: en_pages,
        common: en_common,
        cookiebanner: en_cookiebanner,
        managementReports: en_managementReports,
        threatHunting: en_threatHunting,
        introductionHotspots: en_introductionHotspots,
        toastMessages: en_toastMessages,
        loginPage: en_loginPage,
        versionChecker: en_versionChecker,
        dashboardMetrics: en_dashboardMetrics,
        sentinelOnboarding: en_sentinelOnboarding,
        detections: en_detections,
        settings: en_settings,
    },
}

declare module "i18next" {
    interface CustomTypeOptions {
        returnNull: false
        resources: (typeof resources)["en"] // This part ensurces the translate function knows all the translation keys for type safety & autocompletion.
    }
}

i18n.use(initReactI18next).init({
    returnNull: false,
    resources: resources,
    debug: import.meta.env.VITE_ENVIRONMENT_ID === "development",
    lng: "en",
    fallbackLng: "en",
    fallbackNS: "pages",
    interpolation: {
        escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
})

export default i18n
