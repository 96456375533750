import NavigationDropoutMenu from "@designSystem/overlays/NavigationDropoutMenu"
import React, { useContext } from "react"
import NavigationButton from "./navigationItems/NavigationButton"
import { getNavigationItems } from "@config/navigationConfig"
import { useActiveEyed } from "@hooks/useActiveEyed"
import { UserContext } from "@/providers/UserContext"
import { BuildingLibraryIcon, Cog6ToothIcon } from "@heroicons/react/24/outline"
import { getRouterLinks } from "@config/routes/routeLinksConfig"
import Avatar from "@designSystem/layout/Avatar"
import { useAuth0 } from "@auth0/auth0-react"
import { Typography } from "@eyectrl/baseplate-design-system"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { ChevronRightIcon, ArrowRightEndOnRectangleIcon } from "@heroicons/react/24/solid"
import { QuestionMarkCircleIcon } from "@heroicons/react/24/outline"

export const DropoutNavigation: React.FC = () => {
    const navigationItems = getNavigationItems()
    const routerLinks = getRouterLinks()
    const activeEyed = useActiveEyed()
    const { isPortalAdmin } = useContext(UserContext)
    const { user } = useAuth0()
    const navigate = useNavigate()
    const { t } = useTranslation()

    return (
        <div className="space-y-2">
            {isPortalAdmin && (
                <NavigationButton
                    label={"Admin Interface"}
                    iconLeft={{ component: BuildingLibraryIcon }}
                    onNavigate={() => {
                        return getRouterLinks().admin.root()
                    }}
                />
            )}

            <NavigationDropoutMenu
                renderButton={(active) => {
                    return (
                        <NavigationButton
                            hasHoverClass={active}
                            label={"Help"}
                            contentLeft={<QuestionMarkCircleIcon width={24} />}
                            contentRight={<ChevronRightIcon width={16} height={16} />}
                        />
                    )
                }}
                buttonClass="w-full"
            >
                {(closePanel) => {
                    return (
                        <div className="p-3 rounded-md space-y-2 overflow-x-auto">
                            <NavigationButton
                                label={navigationItems.support.label}
                                iconLeft={{ component: navigationItems.support.icon }}
                                onClick={() => {
                                    closePanel()
                                }}
                                onNavigate={() => {
                                    return navigationItems.support.onNavigate({ eyed: activeEyed })
                                }}
                            />
                            <NavigationButton
                                label={navigationItems.knowledgeBase.label}
                                iconLeft={{ component: navigationItems.knowledgeBase.icon }}
                                onNavigate={() => {
                                    return navigationItems.knowledgeBase.onNavigate()
                                }}
                            />
                        </div>
                    )
                }}
            </NavigationDropoutMenu>

            <NavigationDropoutMenu
                renderButton={(active) => {
                    return (
                        <NavigationButton
                            hasHoverClass={active}
                            label={user?.name ?? ""}
                            contentLeft={
                                <Avatar
                                    testid="usermenu-avatar"
                                    name={user?.name ?? ""}
                                    size="sm"
                                    // applies negative margin to correct the width of the avatar with the width of the rest of the nav items.
                                    className="-ml-1.5"
                                />
                            }
                            contentRight={<ChevronRightIcon width={16} height={16} />}
                        />
                    )
                }}
                buttonClass="w-full"
            >
                {(closePanel) => {
                    return (
                        <div className=" rounded-md space-y-2 overflow-x-auto">
                            <div className="flex items-center gap-4 border-b-background-brand-hover-light border-b p-4">
                                <Avatar testid="usermenu-avatar" name={user?.name ?? ""} size="md" />
                                <div>
                                    <Typography
                                        color="text-invert"
                                        variant="body-1-semibold"
                                        textElipsis
                                        shouldPreserveNewLines
                                        shouldPreventWrapping
                                        className="max-w-[200px]"
                                    >
                                        {user?.name}
                                    </Typography>
                                    <Typography
                                        color="text-invert"
                                        variant="body-2"
                                        textElipsis
                                        shouldPreserveNewLines
                                        shouldPreventWrapping
                                        className="max-w-[200px]"
                                    >
                                        {user?.email}
                                    </Typography>
                                </div>
                            </div>

                            <div className="px-4 pb-4 space-y-2">
                                <NavigationButton
                                    label={t("navigation:AccountSettings")}
                                    contentLeft={<Cog6ToothIcon width={24} />}
                                    onClick={() => {
                                        closePanel()
                                        // return navigationItems.settings.root.onNavigate()
                                        navigate(routerLinks.settings.root({ eyed: activeEyed }))
                                    }}
                                />
                                <NavigationButton
                                    label={t("navigation:Logout")}
                                    contentLeft={<ArrowRightEndOnRectangleIcon width={24} />}
                                    onClick={() => {
                                        closePanel()
                                        navigate(routerLinks.logout())
                                    }}
                                />
                            </div>
                        </div>
                    )
                }}
            </NavigationDropoutMenu>
        </div>
    )
}
