import { RadioGroup, Switch, Typography } from "@eyectrl/baseplate-design-system"
import { usePatchUserSettingsV2 } from "@features/userSettings/hooks/usePatchUserSettingsV2"
import { IUserSettings } from "@features/userSettings/userSettingsTypes"
import { InformationCircleIcon } from "@heroicons/react/24/outline"
import { useActiveEyed } from "@hooks/useActiveEyed"
import Tippy from "@tippyjs/react"
import { useState } from "react"
import { useTranslation } from "react-i18next"

export type DigestInterval = "weekly" | "monthly" | "none"

interface Props {
    userSettings: IUserSettings
}

const getInsuranceNotificationState = (settings: IUserSettings): boolean => {
    if (settings.edr_coverage_notifications === true && settings.threathunt_notifications === true) {
        return true
    }

    return false
}

export const InsuranceNotificationOptions: React.FC<Props> = ({ userSettings }) => {
    const { t } = useTranslation()
    const activeEyed = useActiveEyed()
    const { mutate } = usePatchUserSettingsV2(activeEyed)

    const [hasEnabledDetectionsNotifications, setHasEnabledDetectionsNotifications] = useState(
        userSettings.detections_daily_notifications,
    )

    const insuranceSettingState = getInsuranceNotificationState(userSettings)

    // Stores the selected digest setting
    const [digestSetting, setDigestSetting] = useState<DigestInterval>(userSettings.digest_notification_interval)
    // The options in the digest radio group
    const digestSettingOptions = [
        { label: t("common:userMenu.notifications.weekly"), value: "weekly" },
        { label: t("common:userMenu.notifications.monthly"), value: "monthly" },
    ]
    // The selected digest radio option ( falls back to monthly if the digest setting is set to "none")
    const selectedDigestRadioOption =
        digestSettingOptions.find((option) => option.value === digestSetting) ?? digestSettingOptions[1]

    const onChangeDigestSetting = (interval: DigestInterval): void => {
        setDigestSetting(interval)

        mutate({
            digest_notification_interval: interval,
        })
    }

    const onChangeResolvedDetections = (value: boolean) => {
        setHasEnabledDetectionsNotifications(value)
        mutate({
            detections_daily_notifications: value,
        })
    }

    return (
        <div className="space-y-4">
            <section className="card p-6">
                <div className="flex gap-2 items-center">
                    <Switch
                        testId="resolved-detections-switch"
                        checked={hasEnabledDetectionsNotifications}
                        disabled={false}
                        onChange={(enabled) => {
                            onChangeResolvedDetections(enabled)
                        }}
                    />
                    <Typography variant="header-4">{t("settings:notifications.resolvedDetections.title")}</Typography>
                </div>
                <Typography color="text-secondary" className="mt-2">
                    {t("settings:notifications.resolvedDetections.subtitle")}
                </Typography>
            </section>

            <section className="card p-6">
                <div className="flex gap-2 items-center">
                    <Switch
                        testId="digest-emails-switch"
                        checked={digestSetting !== "none"}
                        disabled={false}
                        onChange={(newValue) => {
                            if (newValue) {
                                onChangeDigestSetting("monthly")
                            } else {
                                onChangeDigestSetting("none")
                            }
                        }}
                    />
                    <Typography variant="header-4">{t("settings:notifications.digest.title")}</Typography>
                </div>
                <Typography color="text-secondary" className="mt-2">
                    {t("settings:notifications.digest.subtitle")}
                </Typography>

                <div className="mt-4">
                    <RadioGroup
                        selected={selectedDigestRadioOption}
                        disabled={digestSetting === "none"}
                        className="space-y-3"
                        onChange={(value) => {
                            if (value === "monthly") {
                                onChangeDigestSetting("monthly")
                            }

                            if (value === "weekly") {
                                onChangeDigestSetting("weekly")
                            }
                        }}
                        options={digestSettingOptions}
                    ></RadioGroup>
                </div>
            </section>

            <section className="card p-6">
                <div className="flex gap-2 items-center">
                    <Switch checked={insuranceSettingState} disabled={true} onChange={() => {}} />
                    <Typography variant="header-4" color="text-secondary">
                        {t("settings:notifications.insurance.title")}
                    </Typography>
                    <Tippy
                        interactive
                        content={
                            <Typography color="text-invert" variant="body-2">
                                {t("settings:notifications.insurance.tooltip")}
                            </Typography>
                        }
                    >
                        <div>
                            <Typography color="text-inactive">
                                <InformationCircleIcon width={18} />
                            </Typography>
                        </div>
                    </Tippy>
                </div>
                <Typography color="text-secondary" className="mt-2">
                    {t("settings:notifications.insurance.subtitle")}
                </Typography>

                <ul className="list-disc pl-5 space-y-1 mt-4">
                    <li>{t("settings:notifications.insurance.hunts")}</li>
                    <li>{t("settings:notifications.insurance.edr")}</li>
                </ul>
            </section>
        </div>
    )
}
