import React, { ForwardRefExoticComponent } from "react"
import NavigationLink from "./NavigationLink"
import { Typography } from "@eyectrl/baseplate-design-system"
import { EyeCon } from "@designSystem/icons/Icons"

interface Props {
    label: string
    hasHoverClass?: boolean // if true, the active hover class will enabled the hover background ( usefull in dropout nav for example )
    onNavigate?: () => string
    onClick?: () => void // can be used instead of onNavigate
    iconLeft?: {
        component: ForwardRefExoticComponent<Omit<React.SVGProps<SVGSVGElement>, "ref">> | EyeCon // very specific if you want to use a svg icon on the left side.
    }
    contentLeft?: React.ReactNode // Can be used with any react component
    contentRight?: React.ReactNode
    variant?: "primary" | "secondary"
}

const getClassName = (isActive: boolean, variant: Props["variant"]): string => {
    if (variant === "primary") {
        return isActive ? "navigation-button active" : "navigation-button"
    }

    return isActive ? "navigation-button-secondary active" : "navigation-button-secondary"
}

const NavigationButtonContent: React.FC<Props> = ({ label, iconLeft, contentRight, contentLeft, variant }) => {
    return (
        <>
            <div className="flex items-center gap-3">
                {iconLeft && (
                    <div className="w-[20px] flex items-center">
                        <iconLeft.component className="flex-shrink-0 h-5 w-5" aria-hidden="true" />
                    </div>
                )}
                {contentLeft && <div className="w-[20px] flex items-center">{contentLeft}</div>}
                <Typography
                    variant={variant === "primary" ? "body-1-semibold" : "body-1"}
                    color="inherit"
                    shouldPreventWrapping
                    textElipsis
                    className="max-w-[180px]"
                >
                    {label}
                </Typography>
            </div>

            {contentRight && <div className="ml-2">{contentRight}</div>}
        </>
    )
}

const NavigationButton: React.FC<Props> = (props) => {
    const { variant = "primary", onClick, onNavigate } = props

    if (onNavigate) {
        return (
            <NavigationLink
                to={onNavigate()}
                getClassName={(isActive) => getClassName(isActive, variant)}
                onClick={onClick}
            >
                <NavigationButtonContent {...props} variant={variant} />
            </NavigationLink>
        )
    }

    return (
        <button
            className={`${variant === "primary" ? "navigation-button" : "navigation-button-secondary"} w-full ${props.hasHoverClass ? "active-hover" : ""}`}
            onClick={props.onClick}
        >
            <NavigationButtonContent {...props} variant={variant} />
        </button>
    )
}

export default NavigationButton
