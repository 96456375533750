import { getUserSettingsV2Endpoint, patchUserSettingsV2Endpoint } from "@/api/endpoints"
import { UseMutationResult, useMutation, useQueryClient } from "@tanstack/react-query"
import { useTranslation } from "react-i18next"
import { patchRequest } from "@/api/requests"
import { useAuth0 } from "@auth0/auth0-react"
import { toastService } from "@/services/toastService"
import { IUserSettings } from "../userSettingsTypes"

type ReturnValue = UseMutationResult<
    {
        data: string
    },
    Error,
    Partial<IUserSettings>,
    unknown
>

export const usePatchUserSettingsV2 = (eyed: string): ReturnValue => {
    const { t } = useTranslation(["common", "settings"])
    const queryClient = useQueryClient()
    const { getAccessTokenSilently } = useAuth0()
    const patchEndpoint = patchUserSettingsV2Endpoint(eyed)

    return useMutation({
        mutationFn: (variables) => {
            return patchRequest<Partial<IUserSettings>, { data: string }>(
                patchEndpoint.url,
                variables,
                getAccessTokenSilently,
            )
        },
        onSuccess: () => {
            toastService.successMessage(t("settings:notifications.successMessage"))
        },
        onError: () => {
            queryClient.invalidateQueries({ queryKey: getUserSettingsV2Endpoint(eyed).queryKey })
            toastService.errorMessage(t("settings:notifications.errorMessage"))
        },
    })
}
