import { Typography } from "@eyectrl/baseplate-design-system"
import Tippy from "@tippyjs/react"
import React from "react"

interface Props {
    activeButton: string
    buttons: { name: string; icon: React.ReactNode; tooltip?: string }[]
    onChange: (name: string) => void
    testid?: string
}

export const SwitchButton: React.FC<Props> = ({ buttons, onChange, activeButton, testid }) => {
    return (
        <div className="border rounded-lg flex items-center gap-0.5" data-testid={testid}>
            {buttons.map((switchButton) => {
                const button = (
                    <button
                        onClick={() => {
                            onChange(switchButton.name)
                        }}
                        key={switchButton.name}
                        className={`h-[46px] ${activeButton === switchButton.name ? "bg-white" : "bg-brand-inactive-light"} p-3 hover:bg-white rounded-lg items-center flex gap-2`}
                    >
                        {switchButton.icon}
                    </button>
                )

                if (switchButton.tooltip) {
                    return (
                        <Tippy
                            key={`tooltip ${switchButton.name}`}
                            content={
                                <Typography color="text-invert" variant="body-2">
                                    {switchButton.tooltip}
                                </Typography>
                            }
                        >
                            {button}
                        </Tippy>
                    )
                }

                return button
            })}
        </div>
    )
}
