import { IDetectionsFilterSettings } from "@features/detectionsv2/hooks/useDetectionsFilters"
import { IDetectionV2Statistics, ResolutionStatusV2 } from "@features/detectionsv2/types"
import { isNullish } from "./formatUtils"

export type ResolutionStatus =
    | "Normal behaviour, nothing wrong"
    | "Adversarial behaviour, resolved"
    | "Undesired behaviour, not dangerous"
    | "Unsuccessful adversarial behaviour, not dangerous"

export type ResolutionStatusSummary = "Normal behaviour" | "Abnormal behaviour" | "Malicious behaviour"

export const getResolutionStatusText = (
    status?: string,
): { label: ResolutionStatusSummary; labelShort: string; description: string } => {
    switch (status) {
        case "LA":
        case "FP":
        case "FP ESC":
            return {
                label: "Normal behaviour",
                labelShort: "Normal",
                description:
                    "In this situation everything is normal behaviour and no intervention or actions were needed.",
            }

        case "MA":
        case "TP":
        case "TP CONT":
        case "TP ESC":
            return {
                label: "Malicious behaviour",
                labelShort: "Malicious",
                description: "In this situation there was malicious behaviour and is resolved by our analysts.",
            }

        case "LUND":
            return {
                label: "Abnormal behaviour",
                labelShort: "Abnormal",
                description: "In this situation there was abnormal behaviour, no intervention or actions was needed.",
            }

        case "MNOT":
        case "TP NOT":
        case "MA NOT":
            return {
                label: "Malicious behaviour",
                labelShort: "Malicious",
                description:
                    "In this situation there was malicious behaviour, classified as an unsuccessful attempt and no intervention or action was needed.",
            }
    }

    return {
        label: "Normal behaviour",
        labelShort: "Normal",
        description: "In this situation everything is normal behaviour and no intervention or actions were needed.",
    }
}

// Builds an array of resolution status tags
// Maps between normal, abnormal and malicous back to 'LA' 'LUND', etc kind of tags.
export const translateFilterSettingsToResolutionStatusTags = (filterSettings: IDetectionsFilterSettings) => {
    const resolutionStatusTags: ResolutionStatusV2[] = []

    if (filterSettings.normalBehaviour) {
        resolutionStatusTags.push("LA")
    }

    if (filterSettings.abnormalBehaviour) {
        resolutionStatusTags.push("LUND")
    }

    if (filterSettings.maliciousBehaviour) {
        resolutionStatusTags.push("MA", "MNOT")
    }

    // If all filters are turned off, return them as all on.
    if (resolutionStatusTags.length === 0) {
        return ["LA", "LUND", "MA", "MNOT"]
    }

    return resolutionStatusTags
}

/**
 * Based on the data from the detections statistics endpoint group the counts into normal, abnormal and mailicous categories
 */
export const getResolutionStatusCategoriesCount = (
    detectionsByResolutionStatus?: IDetectionV2Statistics["count_detections_by_resolution_status_last_30_days"],
) => {
    if (isNullish(detectionsByResolutionStatus)) {
        return {
            normal: 0,
            abnormal: 0,
            malicious: 0,
        }
    }

    return {
        normal: detectionsByResolutionStatus?.LA ?? 0,
        abnormal: detectionsByResolutionStatus?.LUND ?? 0,
        malicious: (detectionsByResolutionStatus?.MA ?? 0) + (detectionsByResolutionStatus?.MNOT ?? 0),
    }
}
