import React, { useContext } from "react"
import { NotificationPreference } from "./NotificationPreference"
import { UserContext } from "@/providers/UserContext"
import { useUserSettingsV1 } from "./hooks/useUserSettingsV1"
import { Skeleton } from "@designSystem/feedback/skeletons/Skeleton"
import { isNullish } from "@utils/formatUtils"

export const NotificationSettings: React.FC = () => {
    const { userOrganisations } = useContext(UserContext)
    const { data: userSettingsData, isPending } = useUserSettingsV1()

    return (
        <div className="text-left flex flex-col">
            {isPending && (
                <div className="border-b p-6 space-y-4">
                    <Skeleton className="h-[20px] w-[100%]" />
                    <Skeleton className="h-[40px] w-[100%" />
                </div>
            )}
            <div className="divide-y space-y-4">
                {!isPending &&
                    userOrganisations.map((organisation) => {
                        // Check if a setting is found for the organistion
                        const organistionUserSetting = userSettingsData?.data.settings?.find(
                            (setting) => setting.eyed === organisation.eyed,
                        )

                        // If we have an organistion without a setting, we simply show nothing ( should not happen )
                        if (isNullish(organistionUserSetting)) {
                            return null
                        }

                        return (
                            <NotificationPreference
                                key={`notification-preference-${organisation.eyed}`}
                                organisation={organisation}
                                userSetting={organistionUserSetting}
                            />
                        )
                    })}
            </div>
        </div>
    )
}
