import { generatePath } from "react-router-dom"
import { getRoutes } from "./routeConfig"

export interface BasePageParams {
    eyed: string
}
export interface DetailPageParams extends BasePageParams {
    id: string
}

export interface AwarenessLinkCollection {
    root: (params: BasePageParams) => string
    overview: (params: BasePageParams) => string
    recipientInfo: (params: BasePageParams) => string
    additionalInfo: (params: BasePageParams) => string
}

export interface DetectionsLinkCollection {
    root: (params: BasePageParams) => string
    detail: (params: DetailPageParams) => string
}

export interface RecommendationsLinkCollection {
    root: (params: BasePageParams) => string
    detail: (params: DetailPageParams) => string
}

export interface ReportsLinkCollection {
    root: (params: BasePageParams) => string
}

export interface AdminLinkCollection {
    root: () => string
    awarenessCSV: () => string
    awarenessOrganisations: () => string
}

export interface ThreatHuntingLinkCollection {
    root: (params: BasePageParams) => string
    detail: (params: DetailPageParams) => string
}

export interface SettingsLinkCollection {
    root: (params: BasePageParams) => string
    notifications: (params: BasePageParams) => string
    tenants: (params: BasePageParams) => string
}

export interface RouterLinkCollection {
    awareness: AwarenessLinkCollection
    detections: DetectionsLinkCollection
    recommendations: RecommendationsLinkCollection
    dashboard: (params: BasePageParams) => string
    support: (params: BasePageParams) => string
    riskLocales: () => string
    logout: () => string
    admin: AdminLinkCollection
    announcements: () => string
    threatHunting: ThreatHuntingLinkCollection
    reports: ReportsLinkCollection
    sentinelOnboarding: (params: BasePageParams) => string
    settings: SettingsLinkCollection
}

export const getRouterLinks = (): RouterLinkCollection => {
    const routes = getRoutes()

    const routerLinks: RouterLinkCollection = {
        dashboard: (params: BasePageParams) => generatePath(routes.dashboard.path, params),
        support: (params: BasePageParams) => generatePath(routes.support.path, params),
        riskLocales: () => generatePath(routes.riskLocales.path),
        logout: () => generatePath(routes.logout.path),
        awareness: {
            root: (params: BasePageParams) => generatePath(routes.awareness.root.path, params),
            overview: (params: BasePageParams) => generatePath(routes.awareness.overview.path, params),
            recipientInfo: (params: BasePageParams) => generatePath(routes.awareness.recipientInfo.path, params),
            additionalInfo: (params: BasePageParams) => generatePath(routes.awareness.additionalInfo.path, params),
        },
        recommendations: {
            root: (params: BasePageParams) => generatePath(routes.recommendations.root.path, params),
            detail: (params: DetailPageParams) => generatePath(routes.recommendations.detail.path, params),
        },
        detections: {
            root: (params: BasePageParams) => generatePath(routes.detections.root.path, params),
            detail: (params: DetailPageParams) => generatePath(routes.detections.detail.path, params),
        },
        admin: {
            root: () => generatePath(routes.admin.root.path),
            awarenessCSV: () => generatePath(routes.admin.awarenessCSVAdmin.path),
            awarenessOrganisations: () => generatePath(routes.admin.awarenessOrganisations.path),
        },
        announcements: () => generatePath(routes.announcements.path),
        threatHunting: {
            root: (params: BasePageParams) => generatePath(routes.threatHunting.root.path, params),
            detail: (params: DetailPageParams) => generatePath(routes.threatHunting.detail.path, params),
        },
        reports: {
            root: (params: BasePageParams) => generatePath(routes.reports.path, params),
        },
        sentinelOnboarding: (params: BasePageParams) => generatePath(routes.sentinelOnboarding.path, params),
        settings: {
            root: (params: BasePageParams) => generatePath(routes.settings.root.path, params),
            notifications: (params: BasePageParams) => generatePath(routes.settings.notifications.path, params),
            tenants: (params: BasePageParams) => generatePath(routes.settings.tenants.path, params),
        },
    }

    return routerLinks
}
