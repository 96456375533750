// List of all the available feature flags.
export const featureFlagConfig = {
    announcementBanner: "AnnouncementBanner",
    recommendationCSVDownload: "RecommendationCSVDownload",
    dashboardHotspotTour: "DashboardHotspotTour",
    versionChecker: "VersionChecker",
    riskExperimentalRecommendations: "RiskExperimentalRecommendations",
    detectionsCardsTables: "detectionsCardsTables",
    insuranceUpsellExperiment: "InsuranceUpsellExperiment",
    chargebeeWidget: "ChargebeeWidget",
    notificationsV2: "NotificationsV2",
}
