import React, { useState } from "react"
import { IUserSettings } from "../userSettingsTypes"
import { Checkbox, Typography } from "@eyectrl/baseplate-design-system"
import { usePatchUserSettingsV2 } from "../hooks/usePatchUserSettingsV2"
import { useActiveEyed } from "@hooks/useActiveEyed"
import { useTranslation } from "react-i18next"

interface Props {
    userSettings: IUserSettings
}

export const FindingsNotificationSettings: React.FC<Props> = ({ userSettings }) => {
    const activeEyed = useActiveEyed()
    const { mutate } = usePatchUserSettingsV2(activeEyed)
    const { t } = useTranslation()

    const [hasEnabledDetectionsNotifications, setHasEnabledDetectionsNotifications] = useState(
        userSettings.detections_daily_notifications,
    )
    const [hasEnabledThreatHuntNotifications, setHasEnabledThreatHuntNotifications] = useState(
        userSettings.threathunt_notifications,
    )

    const onChangeDetectionsNotification = (value: boolean) => {
        setHasEnabledDetectionsNotifications(value)
        // Patch the API
        mutate({
            detections_daily_notifications: value,
        })
    }

    const onChangeThreatHuntNotification = (value: boolean) => {
        setHasEnabledThreatHuntNotifications(value)
        mutate({
            threathunt_notifications: value,
        })
    }

    return (
        <section className="card p-6">
            <Typography variant="header-4">{t("settings:notifications.findings.title")}</Typography>

            <Typography color="text-secondary" className="mt-2">
                {t("settings:notifications.findings.subtitle")}
            </Typography>

            <div className="mt-4 flex flex-col gap-3">
                <Checkbox
                    label={t("settings:notifications.findings.hunts")}
                    value={hasEnabledThreatHuntNotifications}
                    onChange={onChangeThreatHuntNotification}
                />
                <Checkbox
                    label={t("settings:notifications.findings.detections")}
                    value={hasEnabledDetectionsNotifications}
                    onChange={onChangeDetectionsNotification}
                />
            </div>
        </section>
    )
}
