import { UserContext } from "@/providers/UserContext"
import { PageHeader } from "@components/header/PageHeader"
import { DetailPageLayout } from "@components/layout/PageLayout"
import { Collapse } from "@designSystem/layout/Collapse"
import { Typography } from "@eyectrl/baseplate-design-system"
import { UserSettingsNavigation } from "@features/userSettings/components/UserSettingsNavigation"
import { DATE_FORMATS, formatDate } from "@utils/dateUtils"
import { isNullish } from "@utils/formatUtils"
import React, { useContext } from "react"
import { Trans, useTranslation } from "react-i18next"

export const TenantSettingsPage: React.FC = () => {
    const { t } = useTranslation()
    const { activeOrganisation } = useContext(UserContext)

    return (
        <DetailPageLayout
            sidebarLeft={{
                sidebarContent: (
                    <div>
                        <UserSettingsNavigation />
                    </div>
                ),
                sidebarContentActive: true,
            }}
        >
            <div>
                <PageHeader
                    title={t("dashboard.tenants.modal.title")}
                    subtitle={
                        <Typography variant="body-1" color="text-secondary">
                            {t("dashboard.tenants.modal.description")}
                        </Typography>
                    }
                />

                <div className="space-y-4">
                    {!isNullish(activeOrganisation?.azure_tenants_updated_at) && (
                        <div className="flex items-center gap-2">
                            <Typography color="text-secondary" variant="body-2">
                                {`${t("dashboard.tenants.modal.lastUpdated")}:`}
                            </Typography>
                            <Typography variant="body-2">
                                {formatDate(
                                    activeOrganisation?.azure_tenants_updated_at,
                                    DATE_FORMATS.DATE_TIME_FORMAT,
                                )}
                            </Typography>
                        </div>
                    )}

                    <div className="space-y-3">
                        {activeOrganisation?.azure_tenants?.map((tenant) => {
                            return (
                                <div key={`tenant-block-${tenant.id}`} className="border rounded-md bg-white">
                                    <Collapse
                                        defaultOpen={false}
                                        title={
                                            <div className="">
                                                <Typography variant="body-1-semibold">{tenant.name}</Typography>
                                            </div>
                                        }
                                        buttonClassName="w-full flex justify-between p-4 items-center"
                                    >
                                        <div className="p-4 border-t max-h-[250px] overflow-auto">
                                            <Typography variant="body-2" color="text-secondary">
                                                {t("dashboard.tenants.modal.connectedDomains")}
                                            </Typography>

                                            <ul>
                                                {tenant.domains?.map((tenantDomain) => {
                                                    return <li key={`tenant-domain-${tenantDomain}`}>{tenantDomain}</li>
                                                })}
                                            </ul>
                                        </div>
                                    </Collapse>
                                </div>
                            )
                        })}
                    </div>
                </div>

                <div className="mt-4">
                    <Typography variant="body-2">
                        <Trans
                            i18nKey="dashboard.tenants.modal.support"
                            components={[
                                <a
                                    key="kb-link"
                                    className="inline-block text-text-link-light hover:underline font-semibold"
                                    href="mailto:support@eye.security"
                                />,
                            ]}
                        />
                    </Typography>
                </div>
            </div>
        </DetailPageLayout>
    )
}
