import i18n from "../i18next"
import {
    BookOpenIcon,
    ChatBubbleLeftRightIcon,
    AcademicCapIcon,
    ShieldCheckIcon,
    EyeIcon,
    Squares2X2Icon,
} from "@heroicons/react/24/outline"
import { getRouterLinks } from "./routes/routeLinksConfig"
import TargetIcon from "@assets/icons/targetIcon.svg?react"
import ReportsIcon from "@assets/icons/reportsIcon.svg?react"

export const getNavigationItems = () => {
    const routerLinks = getRouterLinks()

    const navigationItems = {
        dashboard: {
            label: i18n.t("navigation:Dashboard"),
            onNavigate: routerLinks.dashboard,
            icon: Squares2X2Icon,
        },
        detections: {
            label: i18n.t("navigation:Detections"),
            onNavigate: routerLinks.detections.root,
            icon: EyeIcon,
        },
        recommendations: {
            label: i18n.t("navigation:Recommendations"),
            onNavigate: routerLinks.recommendations.root,
            icon: ShieldCheckIcon,
        },
        awareness: {
            root: {
                label: i18n.t("navigation:Awareness.Home"),
                onNavigate: routerLinks.awareness.root,
                icon: AcademicCapIcon,
            },
            overview: {
                label: i18n.t("navigation:Awareness.Overview"),
                onNavigate: routerLinks.awareness.overview,
                icon: AcademicCapIcon,
            },
            recipientInfo: {
                label: i18n.t("navigation:Awareness.recipientInformation"),
                onNavigate: routerLinks.awareness.recipientInfo,
                icon: AcademicCapIcon,
            },
            additionalInfo: {
                label: i18n.t("navigation:Awareness.AdditionalInformation"),
                onNavigate: routerLinks.awareness.additionalInfo,
                icon: AcademicCapIcon,
            },
        },
        reports: {
            root: {
                label: i18n.t("navigation:Reports"),
                onNavigate: routerLinks.reports.root,
                icon: <ReportsIcon width={24} className="stroke-icon" />,
            },
        },
        support: {
            label: i18n.t("navigation:Support"),
            onNavigate: routerLinks.support,
            icon: ChatBubbleLeftRightIcon,
        },
        knowledgeBase: {
            label: i18n.t("navigation:Knowledgebase"),
            onNavigate: () => "https://kb.eye.security/",
            icon: BookOpenIcon,
        },
        threatHunting: {
            root: {
                label: i18n.t("navigation:ThreatHunting"),
                onNavigate: routerLinks.threatHunting.root,
                icon: <TargetIcon width={24} className="fill-icon" />,
            },
        },
        settings: {
            root: {
                label: i18n.t("navigation:Settings.personalInformation"),
                onNavigate: routerLinks.settings.root,
            },
            notifications: {
                label: i18n.t("navigation:Settings.notifications"),
                onNavigate: routerLinks.settings.notifications,
            },
            tenants: {
                label: i18n.t("navigation:Settings.tenants"),
                onNavigate: routerLinks.settings.tenants,
            },
        },
    }

    return navigationItems
}
