import NavigationButton from "@components/navigation/navigationItems/NavigationButton"
import { getNavigationItems } from "@config/navigationConfig"
import { Typography } from "@eyectrl/baseplate-design-system"
import { useTranslation } from "react-i18next"
import UserIcon from "@heroicons/react/24/outline/UserIcon"
import { ChargebeeWidget } from "@features/chargebee/ChargebeeWidget"
import { BuildingOfficeIcon } from "@heroicons/react/24/outline"
import { useContext } from "react"
import { UserContext } from "@/providers/UserContext"
import { useFeatureFlagEnabled } from "posthog-js/react"
import { featureFlagConfig } from "@config/featureFlagConfig"
import { useActiveEyed } from "@hooks/useActiveEyed"

export const UserSettingsNavigation: React.FC = () => {
    const navigationItems = getNavigationItems()
    const { t } = useTranslation()
    const { activeOrganisation } = useContext(UserContext)
    const activeEyed = useActiveEyed()
    // Checks to hide tenants / chargebee items.
    const azureTenantsCount = activeOrganisation?.azure_tenants?.length ?? 0
    const featureEnabled = useFeatureFlagEnabled(featureFlagConfig.chargebeeWidget)
    const hideChargebeeWidget =
        featureEnabled === false || featureEnabled === undefined || activeOrganisation?.show_chargebee_widget !== true
    const hideOrganisationSettings = azureTenantsCount === 0 && hideChargebeeWidget === true

    return (
        <div className="space-y-6 px-6 py-8 w-[240px]">
            <section className="space-y-2">
                <div className="flex items-center gap-2">
                    <UserIcon width={20} />
                    <Typography variant="body-1-semibold">{t("navigation:Settings.accountSettings")}</Typography>
                </div>

                <NavigationButton
                    label={t("navigation:Settings.root")}
                    variant="secondary"
                    onNavigate={() => {
                        return navigationItems.settings.root.onNavigate({ eyed: activeEyed })
                    }}
                />

                <NavigationButton
                    variant="secondary"
                    label={t("navigation:Settings.notifications")}
                    onNavigate={() => {
                        return navigationItems.settings.notifications.onNavigate({ eyed: activeEyed })
                    }}
                />
            </section>

            {hideOrganisationSettings === false && (
                <section className="space-y-2">
                    <div className="flex items-center gap-2">
                        <BuildingOfficeIcon width={20} />
                        <Typography variant="body-1-semibold">
                            {t("navigation:Settings.organisationSettings")}
                        </Typography>
                    </div>

                    {azureTenantsCount > 0 && (
                        <NavigationButton
                            variant="secondary"
                            label={t("navigation:Settings.tenants")}
                            onNavigate={() => {
                                return navigationItems.settings.tenants.onNavigate({ eyed: activeEyed })
                            }}
                        />
                    )}

                    <ChargebeeWidget />
                </section>
            )}
        </div>
    )
}
