import { Switch, Typography } from "@eyectrl/baseplate-design-system"
import BuildingBlocksIllustration from "@assets/illustrations/buildingBlocksIllustration.svg?react"
import { useTranslation } from "react-i18next"
import Tippy from "@tippyjs/react"
import classNames from "classnames"

interface Props {
    allowAnalyticalCookies: boolean
    setAllowAnalyticalCookies: (value: boolean) => void
    withHeader?: boolean
}

export const CookieSelection: React.FC<Props> = ({
    allowAnalyticalCookies,
    setAllowAnalyticalCookies,
    withHeader = true,
}) => {
    const { t } = useTranslation()
    return (
        <div>
            {withHeader && (
                <>
                    <div className="flex justify-center w-full">
                        <BuildingBlocksIllustration className="h-[150px]" />
                    </div>

                    <section className="mt-8">
                        <Typography variant="header-3" spacingBottom>
                            {t("cookiebanner:cookieSelection.title")}
                        </Typography>
                        <Typography variant="body-1">{t("cookiebanner:cookieSelection.description")}</Typography>
                    </section>
                </>
            )}

            <section className={classNames([{ "border-t mt-8": withHeader }])}>
                <div className="py-4 flex gap-3">
                    <Tippy content={t("cookiebanner:cookieSelection.functionalCookies.tooltip")} placement={"top"}>
                        <div className="flex items-start mt-1">
                            <Switch
                                checked={true}
                                disabled={true}
                                testId="functional-cookie-switch"
                                onChange={() => {}}
                            />
                        </div>
                    </Tippy>
                    <div>
                        <Typography variant="body-1-semibold">
                            {t("cookiebanner:cookieSelection.functionalCookies.title")}
                            <span className="text-neutral-100 font-normal ml-2">( required )</span>
                        </Typography>
                        <Typography color="text-secondary">
                            {t("cookiebanner:cookieSelection.functionalCookies.description")}
                        </Typography>
                    </div>
                </div>

                <div className="py-4 flex gap-3">
                    <div className="flex items-start mt-1">
                        <Switch
                            testId="analytics-cookie-switch"
                            checked={allowAnalyticalCookies}
                            onChange={() => {
                                setAllowAnalyticalCookies(!allowAnalyticalCookies)
                            }}
                        />
                    </div>
                    <div>
                        <Typography variant="body-1-semibold">
                            {t("cookiebanner:cookieSelection.analyticalCookies.title")}
                        </Typography>
                        <Typography color="text-secondary">
                            {t("cookiebanner:cookieSelection.analyticalCookies.description")}
                        </Typography>
                    </div>
                </div>
            </section>
        </div>
    )
}
