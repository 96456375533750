import { useAuth0 } from "@auth0/auth0-react"
import { ConsentBanner } from "@components/consentBanner/ConsentBanner"
import { PageHeader } from "@components/header/PageHeader"
import { DetailPageLayout } from "@components/layout/PageLayout"
import { TextInput } from "@designSystem/inputs/TextInput"
import Avatar from "@designSystem/layout/Avatar"
import { Typography } from "@eyectrl/baseplate-design-system"
import { UserSettingsNavigation } from "@features/userSettings/components/UserSettingsNavigation"
import React from "react"
import { useTranslation } from "react-i18next"

export const PersonalSettingsPage: React.FC = () => {
    const { t } = useTranslation()
    const { user } = useAuth0()

    return (
        <DetailPageLayout
            sidebarLeft={{
                sidebarContent: (
                    <div className="">
                        <UserSettingsNavigation />
                    </div>
                ),
                sidebarContentActive: true,
            }}
        >
            <PageHeader
                title={t("settings:account.title")}
                subtitle={
                    <Typography variant="body-1" color="text-secondary">
                        {t("settings:account.subtitle")}
                    </Typography>
                }
            />

            <div className="space-y-3">
                <section className="p-6 card">
                    <div className="flex items-center gap-4 ">
                        <Avatar testid="usermenu-avatar" name={user?.name ?? ""} size="md" className="shrink-0" />
                        <div>
                            <Typography
                                variant="body-1-semibold"
                                textElipsis
                                shouldPreserveNewLines
                                shouldPreventWrapping
                            >
                                {user?.name}
                            </Typography>
                            <Typography variant="body-2" textElipsis shouldPreserveNewLines shouldPreventWrapping>
                                {user?.email}
                            </Typography>
                        </div>
                    </div>
                </section>

                <section className="p-6 card">
                    <Typography variant="body-1-semibold">{t("settings:account.personalInfoSection.title")}</Typography>
                    <div className="space-y-3 mt-3">
                        <div className="flex gap-5">
                            <div className="flex-1">
                                <Typography variant="body-3">
                                    {t("settings:account.personalInfoSection.firstName")}
                                </Typography>
                                <TextInput value={user?.name} className="" disabled />
                            </div>
                            <div className="flex-1">
                                <Typography variant="body-3">
                                    {t("settings:account.personalInfoSection.lastName")}
                                </Typography>
                                <TextInput value={user?.family_name} className="" disabled />
                            </div>
                        </div>
                        <div className="w-full">
                            <Typography variant="body-3">{t("settings:account.personalInfoSection.email")}</Typography>
                            <TextInput value={user?.email} className="w-full" disabled />
                        </div>
                    </div>
                </section>

                <section className="card p-6">
                    <Typography variant="body-1-semibold">{t("navigation:Settings.cookiePreferences")}</Typography>

                    <ConsentBanner variant="summary" />
                </section>
            </div>
        </DetailPageLayout>
    )
}
