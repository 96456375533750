import { UserSettingV1 } from "./useUserSettingsV1"
import { getUserSettingsV1Endpoint, postUserOrganisationSettingsEndpoint } from "@/api/endpoints"
import { UseMutationResult, useMutation, useQueryClient } from "@tanstack/react-query"
import { useTranslation } from "react-i18next"
import { postRequest } from "@/api/requests"
import { useAuth0 } from "@auth0/auth0-react"
import { toastService } from "@/services/toastService"

interface UserSettingUpdate extends UserSettingV1 {
    email: string
}

type ReturnValue = UseMutationResult<
    {
        data: string
    },
    Error,
    UserSettingUpdate,
    unknown
>

export const useUserOrganistionSettingsMutationV1 = (eyed: string): ReturnValue => {
    const { t } = useTranslation(["common"])
    const queryClient = useQueryClient()
    const { getAccessTokenSilently } = useAuth0()
    const userOrgEndpoint = postUserOrganisationSettingsEndpoint(eyed)

    return useMutation({
        mutationFn: (variables) => {
            return postRequest<UserSettingUpdate, { data: string }>(
                userOrgEndpoint.url,
                variables,
                getAccessTokenSilently,
            )
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: getUserSettingsV1Endpoint().queryKey })
            toastService.successMessage(t("common:userMenu.notifications.updateSuccess"))
        },
        onError: () => {
            toastService.errorMessage(t("common:userMenu.notifications.updateError"))
        },
    })
}
