import React, { useContext } from "react"
import { ChargebeeDatasource, useChargebeeSession } from "./useChargebeeSession"
import { useActiveEyed } from "@hooks/useActiveEyed"
import { useTranslation } from "react-i18next"
import { featureFlagConfig } from "@config/featureFlagConfig"
import { useFeatureFlagEnabled } from "posthog-js/react"
import { toastService } from "@/services/toastService"
import { ArrowPathIcon } from "@heroicons/react/24/solid"
import * as Sentry from "@sentry/react"
import { UserContext } from "@/providers/UserContext"
import NavigationButton from "@components/navigation/navigationItems/NavigationButton"
import { AxiosError } from "axios"
import { useCapturePostHogEvent } from "@/posthog/useCapturePostHogEvent"
import { POSTHOG_CAPTURE_TYPES } from "@/posthog/postHogConfig"

const fallbackTestId = "eye-security-nl-test"

export const ChargebeeWidget: React.FC = () => {
    const activeEyed = useActiveEyed()
    const { activeOrganisation } = useContext(UserContext)
    const { t } = useTranslation()
    const { refetch, isLoading, isFetching } = useChargebeeSession(activeEyed)
    const featureEnabled = useFeatureFlagEnabled(featureFlagConfig.chargebeeWidget)
    const { captureEvent } = useCapturePostHogEvent()

    const handleChargebeeError = (error?: AxiosError | null) => {
        if (error?.response?.status === 403) {
            toastService.errorMessage(t("navigation:Chargebee.unauthorisedError"))
        } else {
            toastService.errorMessage(t("navigation:Chargebee.error"))
        }

        Sentry.captureMessage(`Error loading Chargebee widget`)
    }

    const onClickChargebeeButton = async () => {
        captureEvent(POSTHOG_CAPTURE_TYPES.CHARGEBEE.OPENED_CHARGEBEE_PORTAL)

        try {
            const { data: chargebeeSession, error } = await refetch()
            if (chargebeeSession) {
                loadChargebeePortal(chargebeeSession)
            } else {
                handleChargebeeError(error)
            }
        } catch (error) {
            handleChargebeeError()
        }
    }

    const loadChargebeePortal = async (chargeBeeSession: ChargebeeDatasource) => {
        try {
            // @ts-ignore ( ts doesn't know chargebee is available on the window object )
            const chargebee = window.Chargebee.init({
                site: chargeBeeSession.meta_data.chargebeeTenant ?? fallbackTestId,
            })

            chargebee.setPortalSession(chargeBeeSession.data)
            chargebee.createChargebeePortal().open({
                close() {
                    chargebee.logout()
                    // @ts-ignore
                    window.Chargebee.tearDown()
                },
            })
        } catch (error) {
            handleChargebeeError()
        }
    }

    if (
        featureEnabled === false ||
        featureEnabled === undefined ||
        activeOrganisation?.show_chargebee_widget !== true
    ) {
        return null
    }

    return (
        <NavigationButton
            label={t("navigation:Chargebee.label")}
            contentRight={isLoading || isFetching ? <ArrowPathIcon className="animate-spin" width={18} /> : null}
            onClick={onClickChargebeeButton}
            variant="secondary"
        />
    )
}
