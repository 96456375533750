import { UserContext } from "@/providers/UserContext"
import { PageHeader } from "@components/header/PageHeader"
import { DetailPageLayout } from "@components/layout/PageLayout"
import { NoDataMessage } from "@designSystem/feedback/notifications/NoDataMessage"
import { CardSkeleton } from "@designSystem/feedback/skeletons/CardSkeleton"
import { Typography } from "@eyectrl/baseplate-design-system"
import { CyberGuardNotificationOptions } from "@features/userSettings/components/notifications/CyberGuardNotificationOptions"
import { InsuranceNotificationOptions } from "@features/userSettings/components/notifications/InsuranceNotificationOptions"
import { UserSettingsNavigation } from "@features/userSettings/components/UserSettingsNavigation"
import { useActiveEyed } from "@hooks/useActiveEyed"
import { organisationUtils } from "@utils/organisationUtils"
import React, { Fragment, useContext } from "react"
import { useTranslation } from "react-i18next"
import ErrorIllustration from "@assets/illustrations/powercordIllustration.svg?react"
import { useUserSettingsV2 } from "@features/userSettings/hooks/useUserSettingsV2"
import { useFeatureFlagEnabled } from "posthog-js/react"
import { featureFlagConfig } from "@config/featureFlagConfig"
import { NotificationSettings } from "@features/userMenu/NotificationSettings"

export const NotificationsSettingsPage: React.FC = () => {
    const { t } = useTranslation()
    const { activeOrganisation } = useContext(UserContext)
    const activeEyed = useActiveEyed()
    const organisationPlan = activeOrganisation ? organisationUtils.getOrganisationPlan(activeOrganisation) : null
    const { data, isPending, isLoading, isFetching } = useUserSettingsV2(activeEyed)
    const notificationsV2Enabled = useFeatureFlagEnabled(featureFlagConfig.notificationsV2)

    return (
        <DetailPageLayout
            sidebarLeft={{
                sidebarContent: (
                    <div>
                        <UserSettingsNavigation />
                    </div>
                ),
                sidebarContentActive: true,
            }}
        >
            <PageHeader
                title={t("navigation:Settings.notifications")}
                subtitle={
                    <Typography variant="body-1" color="text-secondary">
                        {t("settings:notifications.subtitle")}
                    </Typography>
                }
            />

            {isPending || isLoading || isFetching ? (
                <div>
                    <div className="flex flex-col gap-4">
                        <CardSkeleton />
                        <CardSkeleton />
                        <CardSkeleton />
                    </div>
                </div>
            ) : data?.data ? (
                <div>
                    {notificationsV2Enabled ? (
                        <Fragment>
                            {organisationPlan && organisationPlan === "Cyber Guard Insured" ? (
                                <InsuranceNotificationOptions userSettings={data?.data} />
                            ) : (
                                <CyberGuardNotificationOptions userSettings={data?.data} />
                            )}
                        </Fragment>
                    ) : (
                        <NotificationSettings />
                    )}
                </div>
            ) : (
                <div className="card p-6">
                    <NoDataMessage
                        title={t("settings:errorMessage")}
                        subtitle={t("settings:errorSubtitle")}
                        variant="column"
                        image={<ErrorIllustration className="w-[250px]" />}
                    />
                </div>
            )}
        </DetailPageLayout>
    )
}
