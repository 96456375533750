import { UseQueryResult, useQuery } from "@tanstack/react-query"
import { getUserSettingsV2Endpoint } from "../../../api/endpoints"
import { useAuth0 } from "@auth0/auth0-react"
import { getRequest } from "@/api/requests"
import { IUserSettings } from "../userSettingsTypes"

export interface UserSettingsDatasource {
    data?: IUserSettings | null
}

export const useUserSettingsV2 = (eyed: string): UseQueryResult<UserSettingsDatasource, Error> => {
    const { getAccessTokenSilently } = useAuth0()
    const userSettingsEndpoint = getUserSettingsV2Endpoint(eyed)

    return useQuery<UserSettingsDatasource, Error>({
        queryKey: userSettingsEndpoint.queryKey,
        queryFn: () => getRequest(userSettingsEndpoint.url, getAccessTokenSilently),
        gcTime: 0, // We dont want to cache the user settings, and make sure they are fetched every load.
    })
}
