import { create, StateCreator } from "zustand"

interface PostHogSlice {
    postHogConfigured: boolean
    setPostHogConfigured: (postHogConfigured: boolean) => void
    //
    showConsentBanner: boolean
    setShowConsentBanner: (showConsentBanner: boolean) => void
    //
    isUserIdentified: boolean
    setIsUserIdentified: (isIdentified: boolean) => void
    //
    arePostHogUserPropertiesSynced: boolean
    setArePostHogUserPropertiesSynced: (isSynced: boolean) => void
}

interface RiskSlice {
    riskStagingEnabled: boolean
    setRiskStagingEnabled: (enabled: boolean) => void
}

interface NavigationSlice {
    isSidebarOpen: boolean
    setIsSidebarOpen: (isOpen: boolean) => void
}

const createPostHogSlice: StateCreator<PostHogSlice & RiskSlice & NavigationSlice, [], [], PostHogSlice> = (set) => ({
    isUserIdentified: false,
    setIsUserIdentified: (isUserIdentified: boolean) => set({ isUserIdentified: isUserIdentified }),
    // Indicates if posthog consent was configured initially
    postHogConfigured: false,
    setPostHogConfigured: (postHogConfigured: boolean) => set({ postHogConfigured: postHogConfigured }),
    // Showing of consent banner
    showConsentBanner: false,
    setShowConsentBanner: (showConsentBanner: boolean) => set({ showConsentBanner: showConsentBanner }),
    // Property sync
    arePostHogUserPropertiesSynced: false,
    setArePostHogUserPropertiesSynced: (isSynced: boolean) => set({ arePostHogUserPropertiesSynced: isSynced }),
})

const createNavigationSlice: StateCreator<PostHogSlice & RiskSlice & NavigationSlice, [], [], NavigationSlice> = (
    set,
) => ({
    isSidebarOpen: false,
    setIsSidebarOpen: (isOpen: boolean) => set({ isSidebarOpen: isOpen }),
})

const createRiskSlice: StateCreator<PostHogSlice & RiskSlice & NavigationSlice, [], [], RiskSlice> = (set) => ({
    riskStagingEnabled: false,
    setRiskStagingEnabled: (riskStagingEnabled: boolean) => set({ riskStagingEnabled: riskStagingEnabled }),
})

export const useApplicationStore = create<PostHogSlice & RiskSlice & NavigationSlice>()((...a) => ({
    ...createPostHogSlice(...a),
    ...createRiskSlice(...a),
    ...createNavigationSlice(...a),
}))
