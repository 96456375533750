import i18n from "../../i18next"

export interface RouteRecord {
    path: string
    breadcrumbs: string[]
}
export interface NestedRouteRecord {
    [key: string]: RouteRecord
}

// Abstracter type representation for the Routes object
export type RouteRecordCollection = Record<string, RouteRecord | NestedRouteRecord>

const dashboardBreadcrumb = i18n.t("navigation:Breadcrumbs.Dashboard")
const adminBreadcrumb = i18n.t("navigation:Breadcrumbs.Admin")

const routes = {
    dashboard: { path: "/:eyed/dashboard", breadcrumbs: [dashboardBreadcrumb] },
    recommendations: {
        root: {
            path: "/:eyed/recommendations",
            breadcrumbs: [dashboardBreadcrumb, i18n.t("navigation:Breadcrumbs.Recommendations")],
        },
        detail: {
            path: "/:eyed/recommendations/:id",
            breadcrumbs: [
                dashboardBreadcrumb,
                i18n.t("navigation:Breadcrumbs.Recommendations"),
                i18n.t("navigation:Breadcrumbs.RecommendationDetail"),
            ],
        },
    },
    detections: {
        root: {
            path: "/:eyed/detections",
            breadcrumbs: [dashboardBreadcrumb, i18n.t("navigation:Breadcrumbs.Detections")],
        },
        detail: {
            path: "/:eyed/detections/:id",
            breadcrumbs: [
                dashboardBreadcrumb,
                i18n.t("navigation:Breadcrumbs.Detections"),
                i18n.t("navigation:Breadcrumbs.DetectionDetail"),
            ],
        },
    },
    awareness: {
        root: {
            path: "/:eyed/awareness",
            breadcrumbs: [dashboardBreadcrumb, i18n.t("navigation:Breadcrumbs.Awareness.Home")],
        },
        overview: {
            path: "/:eyed/awareness/overview",
            breadcrumbs: [dashboardBreadcrumb, i18n.t("navigation:Breadcrumbs.Awareness.Overview")],
        },
        recipientInfo: {
            path: "/:eyed/awareness/user-info",
            breadcrumbs: [
                dashboardBreadcrumb,
                i18n.t("navigation:Breadcrumbs.Awareness.Home"),
                i18n.t("navigation:Breadcrumbs.Awareness.recipientInformation"),
            ],
        },
        additionalInfo: {
            path: "/:eyed/awareness/additional-info",
            breadcrumbs: [
                dashboardBreadcrumb,
                i18n.t("navigation:Breadcrumbs.Awareness.Home"),
                i18n.t("navigation:Breadcrumbs.Awareness.AdditionalInformation"),
            ],
        },
    },
    admin: {
        root: {
            path: "/admin",
            breadcrumbs: [adminBreadcrumb],
        },
        awarenessCSVAdmin: {
            path: "/admin/awareness/csv",
            breadcrumbs: [adminBreadcrumb],
        },
        awarenessOrganisations: {
            path: "/admin/awareness/organisations",
            breadcrumbs: [adminBreadcrumb],
        },
    },
    threatHunting: {
        root: {
            path: "/:eyed/threat-hunting",
            breadcrumbs: [dashboardBreadcrumb, i18n.t("navigation:Breadcrumbs.ThreatHunting")],
        },
        detail: {
            path: "/:eyed/threat-hunting/:id",
            breadcrumbs: [
                dashboardBreadcrumb,
                i18n.t("navigation:Breadcrumbs.ThreatHunting"),
                i18n.t("navigation:Breadcrumbs.ThreatHuntingDetail"),
            ],
        },
    },
    settings: {
        root: {
            path: "/:eyed/settings/account",
            breadcrumbs: [dashboardBreadcrumb],
        },
        notifications: {
            path: "/:eyed/settings/notifications",
            breadcrumbs: [dashboardBreadcrumb],
        },
        tenants: {
            path: "/:eyed/settings/tenants",
            breadcrumbs: [dashboardBreadcrumb],
        },
    },
    sentinelOnboarding: { path: "/:eyed/sentinel-onboarding", breadcrumbs: [] },
    support: { path: "/:eyed/support", breadcrumbs: [dashboardBreadcrumb, i18n.t("navigation:Breadcrumbs.Support")] },
    riskLocales: { path: "/admin/risk/locales", breadcrumbs: [] },
    logout: { path: "/logout", breadcrumbs: [] },
    announcements: { path: "/announcements", breadcrumbs: [] },
    reports: { path: "/:eyed/reports", breadcrumbs: [] },
}

// These are all the routes, but with optional eyeds
// We can map these to a redirect which enriches the route with the actual eyed
// This is introduced since react-router's useMatch / matchPath functionality does not work on optional paths
// refer to this discussion: https://github.com/remix-run/react-router/discussions/9862
const fallbackRoutes = {
    dashboard: "/:eyed?/dashboard",
    recommendations: {
        root: "/:eyed?/recommendations",
        detail: "/:eyed?/recommendations/:id",
    },
    detections: {
        root: "/:eyed?/detections",
        detail: "/:eyed?/detections/:id",
    },
    awareness: {
        root: "/:eyed?/awareness",
        overview: "/:eyed?/awareness/overview",
        recipientInfo: "/:eyed?/awareness/user-info",
        additionalInfo: "/:eyed?/awareness/additional-info",
    },
    support: "/:eyed?/support",
    riskLocales: "/admin/risk/locales",
    logout: "/logout",
    announcements: "/announcements",
    threatHunting: {
        root: "/:eyed?/threat-hunting",
        detail: "/:eyed?/threat-hunting/:id",
    },
    settings: {
        root: "/:eyed?/settings/account",
        notifications: "/:eyed?/settings/notifications",
        tenants: "/:eyed?/settings/tenants",
    },
    sentinelOnboarding: "/:eyed?/sentinel-onboarding",
    reports: "/reports",
} as const

// as const provides very exact typings
const routesCollection = { ...routes } as const
// This types the routes as an abstracted record which can be easier used for util functions
const abstractRoutesCollection = { ...routes } as RouteRecordCollection

// Default Routes collection that will be returned with very exact types
export const getRoutes = (): typeof routesCollection => {
    return routesCollection
}

// routes collection that will return a more abstract type representation of the routes object for use in util functions
export const getAbstractRoutes = (): RouteRecordCollection => {
    return abstractRoutesCollection
}

export const getFallbackRoutes = () => {
    return fallbackRoutes
}

// Type guard to differentiate between routeRecords and routeRecordCollections
export const isRouteRecord = (route: RouteRecord | RouteRecordCollection): route is RouteRecord => {
    return "path" in route && "breadcrumbs" in route
}
