import { UserContext } from "@/providers/UserContext"
import { Button } from "@eyectrl/baseplate-design-system"
import React, { useContext } from "react"
import { useTranslation } from "react-i18next"
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline"
import Tippy from "@tippyjs/react"
import { Typography } from "@eyectrl/baseplate-design-system"
import { useNavigate } from "react-router-dom"
import { getRouterLinks } from "@config/routes/routeLinksConfig"
import { useActiveEyed } from "@hooks/useActiveEyed"

export const TenantsIndicator: React.FC = () => {
    const { t } = useTranslation()
    const { activeOrganisation, userProfile, updateUserProfileProperty } = useContext(UserContext)
    const azureTenantsCount = activeOrganisation?.azure_tenants?.length ?? 0
    const navigate = useNavigate()
    const activeEyed = useActiveEyed()

    const onClickTenants = () => {
        updateUserProfileProperty({ has_reviewed_ms_tenants: true })
        navigate(getRouterLinks().settings.tenants({ eyed: activeEyed }))
    }

    if (azureTenantsCount === 0) {
        return null
    }

    return (
        <div className="flex items-center gap-1">
            {userProfile.has_reviewed_ms_tenants === false && (
                <Tippy content={t("dashboard.tenants.tooltip")}>
                    <ExclamationTriangleIcon width={20} className="text-text-warning-light" onClick={onClickTenants} />
                </Tippy>
            )}

            <Typography>{`${t("dashboard.tenants.header")}:`}</Typography>
            <Button
                withPadding={false}
                variant="text"
                className="text-text-link-light"
                onClick={onClickTenants}
            >{`${azureTenantsCount} ${t("dashboard.tenants.connected")}`}</Button>
        </div>
    )
}
