import React, { PropsWithChildren } from "react"
import { Outlet } from "react-router-dom"
import { UserContextProvider } from "../../providers/UserContext"
import { AnnouncementBanner } from "@components/announcements/AnnouncementBanner"
import { useApplicationStore } from "@/stores/applicationStore"
import { ConsentBanner } from "@components/consentBanner/ConsentBanner"
import { PostHogStatus } from "@/posthog/PostHogStatus"
import { usePostHogPageviewCapture } from "@/posthog/usePostHogPageviewCapture"
import { AppSidebar } from "./AppSidebar"
import { MobileTopBar } from "./MobileTopBar"
import { VersionChecker } from "@components/versionChecker/VersionChecker"
import { useFeatureFlagEnabled } from "posthog-js/react"
import { featureFlagConfig } from "@config/featureFlagConfig"

const PageWrapper: React.FC<PropsWithChildren> = ({ children }) => {
    return <div className="h-full flex flex-col ">{children}</div>
}

const ContentWrapper: React.FC<PropsWithChildren> = ({ children }) => {
    // Content wrapper has a z-index of 10, to make sure it falls below the app header ( z-20 )
    return <div className="flex flex-1 flex-col sm:flex-row overflow-hidden z-10">{children}</div>
}

export const AppLayout: React.FC<PropsWithChildren> = ({ children }) => {
    // Hook that captures all posthog pageviews from the router -> DON'T REMOVE
    usePostHogPageviewCapture()
    const versionCheckerEnabled = useFeatureFlagEnabled(featureFlagConfig.versionChecker)
    const showConsentBanner = useApplicationStore((state) => state.showConsentBanner)

    return (
        <UserContextProvider>
            <PageWrapper>
                <MobileTopBar />

                {versionCheckerEnabled && <VersionChecker />}

                {/* Inserts a hidden div with the current posthog status ( for debugging / tests ) */}
                <PostHogStatus />

                <AnnouncementBanner />

                {showConsentBanner && <ConsentBanner />}

                <ContentWrapper>
                    <AppSidebar />

                    <main className="flex flex-1 overflow-y-auto">
                        {children}
                        <Outlet />
                    </main>
                </ContentWrapper>
            </PageWrapper>
        </UserContextProvider>
    )
}
